import request from '@/utils/request'

// 查询广告点击管理列表
export function listProblemAdClick(query) {
  return request({
    url: '/work/ProblemAdClick/list',
    method: 'get',
    params: query
  })
}

// 查询广告点击管理详细
export function getProblemAdClick(id) {
  return request({
    url: '/work/ProblemAdClick/' + id,
    method: 'get'
  })
}

// 新增广告点击管理
export function addProblemAdClick(data) {
  return request({
    url: '/work/ProblemAdClick',
    method: 'post',
    data: data
  })
}

// 修改广告点击管理
export function updateProblemAdClick(data) {
  return request({
    url: '/work/ProblemAdClick/update',
    method: 'post',
    data: data
  })
}

// 删除广告点击管理
export function delProblemAdClick(id) {
  return request({
    url: '/work/ProblemAdClick/remove/' + id,
    method: 'get'
  })
}

// 导出广告点击管理
export function exportProblemAdClick(query) {
  return request({
    url: '/work/ProblemAdClick/export',
    method: 'get',
    params: query
  })
}

// 修改广告点击管理
export function statistics(data) {
  return request({
    url: '/work/ProblemAdClick/statistics',
    method: 'post',
    data: data
  })
}